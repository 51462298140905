import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Icon } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Icon Colors</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Container Appearance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Iconography Groups</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Sizing</AnchorLink>
    </AnchorLinks>
    <h2>{`Icon Colors`}</h2>
    <h3>{`Default`}</h3>
    <p>{`The majority of icons will default to `}<inlineCode parentName="p">{`#FFFFFF`}</inlineCode>{`. In creating designs, designers are free to tint the icons to one of the other colors depending on need. This can include highlighting functionality, harmonizing the color palette, integrating with a button, etc.`}</p>
    <p>{`When used within a button, the icon should use the same color as the button text.`}</p>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="ball-pile" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Securitizations
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="ball-pile" />
`}</code></pre>
  </Column>
    </Row>
    <h3>{`Primary #FFFFFF`}</h3>
    <h3>{`Secondary #AB91FC`}</h3>
    <p>{`Commonly used to provide contrast and establish visual harmony, particularly when used in conjunction with the Outline button.`}</p>
    <h3>{`Default-Dark #050505`}</h3>
    <p>{`Required for use on lighter backgrounds and to ensure ADA compliance.`}</p>
    <h3>{`Disabled #828282`}</h3>
    <h3>{`Colors Indicating Status`}</h3>
    <ul>
      <li parentName="ul">{`danger #FF4D4D`}</li>
      <li parentName="ul">{`alert #F5A624`}</li>
      <li parentName="ul">{`success #3BB042`}
        <ul parentName="li">
          <li parentName="ul">{`Typically used to indicate editing functions`}</li>
          <li parentName="ul">{`Also used for dual-action icons in their “on” state`}</li>
        </ul>
      </li>
      <li parentName="ul">{`information #AB91FC`}</li>
    </ul>
    <h2>{`Container Appearance`}</h2>
    <p>{`Except where specified, all icons require a container. The container serves two purposes:`}</p>
    <ol>
      <li parentName="ol">{`To reflect different states (default, hover, disabled, etc.)`}</li>
      <li parentName="ol">{`To standardize the appearance of icons of varying dimensions`}</li>
    </ol>
    <h3>{`Default`}</h3>
    <p>{`The default fill should be invisible. This can be accomplished by matching the color of the container to the background, or by leaving it empty (the latter is what is used in Sketch files).`}</p>
    <h3>{`Hover`}</h3>
    <ul>
      <li parentName="ul">{`Fill: #383838`}</li>
      <li parentName="ul">{`Border: n/a`}</li>
    </ul>
    <h3>{`On Click`}</h3>
    <ul>
      <li parentName="ul">{`Fill: #383838`}</li>
      <li parentName="ul">{`Border: n/a`}</li>
    </ul>
    <h3>{`Active (Select icons only)`}</h3>
    <ul>
      <li parentName="ul">{`Fill and Border should be transparent`}</li>
      <li parentName="ul">{`This is one of two instances in which an icon changes color to indicate function, rather than the container changing color. The icon itself will turn green-50.`}</li>
    </ul>
    <h3>{`Focus`}</h3>
    <ul>
      <li parentName="ul">{`Fill: n/a`}</li>
      <li parentName="ul">{`Border`}
        <ul parentName="li">
          <li parentName="ul">{`#FFFFFF`}</li>
          <li parentName="ul">{`2px wide for base icon`}</li>
          <li parentName="ul">{`Gaussian blur of 1`}</li>
          <li parentName="ul">{`Inner shadow #000000 with an Alpha of 30%`}
            <ul parentName="li">
              <li parentName="ul">{`X=0`}</li>
              <li parentName="ul">{`Y=1`}</li>
              <li parentName="ul">{`BLUR=1`}</li>
              <li parentName="ul">{`SPREAD=0`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h3>{`Disabled`}</h3>
    <ul>
      <li parentName="ul">{`Fill: n/a`}</li>
      <li parentName="ul">{`Fill and Border should be transparent`}</li>
      <li parentName="ul">{`This is one of two instances in which an icon changes color to indicate function (rather than the container changing color). The icon itself will turn #828282.`}</li>
    </ul>
    <h2>{`Sizing`}</h2>
    <h3>{`Small (Base)`}</h3>
    <ul>
      <li parentName="ul">{`the icon must be a maximum of 12x12px, situated inside a container of 16x16px`}</li>
      <li parentName="ul">{`container has a corner radius of 2`}</li>
    </ul>
    <h3>{`Medium (not currently in use in the app)`}</h3>
    <ul>
      <li parentName="ul">{`the icon itself must be a maximum of 16x16px, situated inside a container of 20x20px`}</li>
      <li parentName="ul">{`container has a corner radius of 2`}</li>
    </ul>
    <h3>{`Navigation Icons`}</h3>
    <ul>
      <li parentName="ul">{`Navigation icons are located on the left and top navigational bars of the app.`}</li>
      <li parentName="ul">{`The icon itself must be a maximum of 16x16px, situated inside a container of 20x20px`}</li>
      <li parentName="ul">{`container has a corner radius of 2`}</li>
    </ul>
    <h3>{`Icons with No Containers`}</h3>
    <h4>{`Other`}</h4>
    <ul>
      <li parentName="ul">{`the X modal close icon is 16x16px`}</li>
    </ul>
    <h4>{`Pill`}</h4>
    <ul>
      <li parentName="ul">{`the edit and Xicons are 12x12px`}</li>
    </ul>
    <h4>{`Beta`}</h4>
    <ul>
      <li parentName="ul">{`the beta icon (a custom made flask) is meant to be used temporarily to call out features that are in beta`}</li>
      <li parentName="ul">{`it is purely for informative purposes and is therefore not clickable and does not require a container`}</li>
      <li parentName="ul">{`the typical size is 10x12px but can be adjusted depending on usage`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      